import React, { Component } from "react"
import { css } from "@emotion/react"

import PropTypes from "prop-types"

import { graphql } from "gatsby"
import * as Libs from "libs"
import * as Modules from "../components/modules"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

class PostJournal extends Component {
  //--------------------------------------
  //  Libs.Styles
  //--------------------------------------

  articleHeaderCss = () => {
    let lessPabCss = null
    if (this.state.ready) {
      lessPabCss = css`
        height: ${this.articleHeader.clientHeight}px;
      `
    }
    return css`
      ${Libs.Common.Func.getPcSpVwValue("margin-top", 0, 0, true)}
      ${Libs.Styles.Mq.lessPab} {
        ${lessPabCss}
      }
    `
  }

  articleHeaderTextContCss = () =>
    css`
      margin-left: auto;
      margin-right: auto;
      width: calc(100vw - ${Libs.Common.Config.siteMargin}vw);

      ${Libs.Styles.Mq.moreTab} {
        position: sticky;
        bottom: calc(20% - 25px);

        /*color: #fff;*/
        margin-bottom: -14px;
      }
    `
  h1ContCss = () =>
    css`
      ${Libs.Common.Func.getPcSpVwValue("margin-top", 10, 10, true)}
      ${Libs.Styles.Mq.lessPab} {
      }
    `
  headLineCss = () =>
    css`
      text-align: center;
      letter-spacing: 0;
      line-height: 0.9;
    `

  imgContCss = aspectRatio => {
    if (aspectRatio <= 1) {
      return css`
        height: 100vh;
        margin-left: auto;
        margin-right: auto;
        .gatsby-image-wrapper {
          height: 100%;
        }
      `
    }
  }

  pCss = () => {
    return css`
      ${Libs.Common.Func.getPcSpVwValue("margin-top", 30, 20, true)}
      width: 50vw;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    `
  }
  tagContCss = () =>
    css`
      ${Libs.Common.Func.getPcSpVwValue("margin-top", 70, 40, true)}

      text-align:center;
    `
  inlineAncher = () => css``
  //--------------------------------------
  //  Scripts
  //--------------------------------------

  constructor(props) {
    super(props)

    this.h1cont = React.createRef()
    this.infoCont = React.createRef()
    this.captionCont = React.createRef()
    this.infoHeaderCont = React.createRef()
    this.articleHeader = React.createRef()

    this.counter = 0
    this.state = {
      ready: false,
      display: false,
    }
  }

  componentDidMount() {
    Libs.Common.Config.excuteTransition()
  }
  shouldComponentUpdate(nextProps, nextState) {
    return false
  }
  render() {
    const { data, pageContext } = this.props
    const journal = data.contentfulContentJournal
    const { next, prev } = pageContext
    const lang = journal.node_locale
    let schemaOrgJSONLDList = []
    schemaOrgJSONLDList.push(
      Libs.Common.SEO.getBreadcrumbSchemaOrgJSONLD(
        [
          { name: "Home", url: `/${lang}/` },
          {
            name: "Journal",
            url: `/${lang}/${journal.category.slug}/`,
          },
          {
            name: journal.name,
            title: journal.title,

            url: `/${lang}/${journal.category.slug}/${journal.slug}/`,
          },
        ],
        {
          next: `/${lang}/${journal.category.slug}/${next.slug}/`,
          prev: `/${lang}/${journal.category.slug}/${prev.slug}/`,
        }
      )
    )
    //console.log("breadcrumbProps -- 1", journal)
    return (
      <>
        <Libs.Common.SEO.SEO
          title={journal.title}
          lang={lang}
          schemaOrgJSONLD={schemaOrgJSONLDList}
          description={
            journal.description
              ? documentToReactComponents(
                  JSON.parse(journal.description.raw)
                )[0].props.children[0]
              : journal.title
          }
          ogImage={journal.thumbnail.fluid.src}
          link={`${journal.category.slug}/${journal.slug}`}
        />
        <section
          className="article project-container"
          data-prev={prev.slug}
          data-next={next.slug}
          data-current={journal.slug}
          data-currentname={journal.name}
          data-categoryname={journal.category.name}
          data-categoryslug={journal.category.slug}
          data-type={"post"}
        >
          <article className="container content">
            <Modules.StoryElement
              story={journal.story}
              next={next.slug}
              prev={prev.slug}
              category={journal.category}
            />
          </article>
        </section>
      </>
    )
  }
}
PostJournal.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default PostJournal

export const pageQuery = graphql`
  query journalQuery($id: String!) {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    contentfulContentJournal(id: { eq: $id }) {
      node_locale
      slug
      name
      title

      date(formatString: "MMM Do, YY")
      thumbnail {
        fluid(maxWidth: 400, quality: 70) {
          ...GatsbyContentfulFluid_withWebp
        }
      }

      description {
        raw
      }
      story {
        items {
          ... on ContentfulElementImage {
            ...Image
          }
          ... on ContentfulElementBlock {
            ...Block
          }
        }
      }

      category {
        name
        slug
      }
      tags {
        name
        slug
      }
    }
  }
`
